import React from 'react';
import ReactDOM from 'react-dom';
import { createStore,  } from 'redux';//applyMiddleware
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from './App/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';

const store = createStore(reducer);

const app = (
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            {/* basename="/datta-able" */}
            <App />
        </BrowserRouter>
    </Provider>
);

// console.log("config.basename")
// console.log(config.basename)

var errors = true;
const userInfo = JSON.parse(localStorage.getItem('FAUser'));
//console.log(userInfo)
//localStorage.removeItem('FAUser');

//valir se obj tem valor ou undifenid ou qual o resultado por defeito antes de login pela 1ª vez
//VALIDATE TOKEN IF dont have errors set error to false
if(userInfo === null || userInfo === undefined || userInfo.token === ""){
    //console.log('with errors');
}else{
    errors = false;
    //console.log('no errors');
}

if(errors){
    if(window.location.pathname !== "/login")
        window.location.replace("/login");
}
    

ReactDOM.render(app, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
