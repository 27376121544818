import * as actionTypes from './actions';
import config from './../config';
//import {getFlightList } from './../Fleetauto/functionsAPI';

const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    isFullScreen: false, // static can't change
    FA_DASHBOARD_BOOKINGS: [],

    FA_OCCUPATION_CALENDAR_ITEMS: [],
    FA_BOOKINGS_CALENDAR_ITEMS : [],
    FA_BOOKINGS_GROUP_CALENDAR_ITEMS : [],

    FA_COUNTRY_SELECT: [],
    FA_AGENCIES_SELECT : [],
    FA_AGENCIES_RATE_SELECT : [],
    FA_STATIONS_SELECT : [],
    FA_GROUPS_SELECT: [],
    FA_CAR_PLATES_SELECT: [],
    FA_CAR_PLATES_CHANGE_SELECT: [],
    FA_CAR_FUEL_RANGE: [],

    FA_BOOKING_CAR_SAVE: [],
    FA_CAR_CHANGE_MOTIVES: [],

    FA_OPERATOR_SELECT: [],

    FA_DRIVER_INFO: [],
    FA_BOOKING_DRIVER_INFO: [],
    FA_SAVE_DRIVER_ACTION: [],

    FA_DRIVER_INFO_FOR_INVOICE: [],

    FA_DRIVERS_LIST: [],
    FA_DRIVERS_HISTORY_LIST: [],

    FA_DRIVER_SEARCH: [],
    FA_DRIVER_HISTORY_SEARCH: [],

    FA_CARS_LIST: [],
    FA_EXTRAS_LIST: [],
    FA_EXTRAS_CALC: [],
//PAYMENTS
    FA_PAYMENTS_LIST: [],
    FA_PAYMENTS_SAVE: [],

    FA_MOVEMENTS_TYPES: [],
    FA_PAYMENTS_TYPES: [],

    FA_BOOKING_STATUSES: [],

    FA_BOOKING_TOLLS_LIST: [],

    FA_AVAILABILITIES_LIST: [],

    FA_BOOKINGS_LIST : [],
    FA_BOOKING_INFO : [],

    FA_RENTALAGREEMENTS_LIST : [],

    FA_FLIGHTS_CORRECTION_LIST : [],
    FA_FLIGHTS_CORRECTION : [],

    FA_BOOKING_TO_INVOICE_LIST : [],
    FA_BOOKING_INVOICED_SAVED_INFO : [],
    //FOR SAVE ACTIONS ON BOOKING FORM
    FA_SAVE_BOOKING : [],
    FA_SAVE_CAR_BOOKING : [],
    FA_SAVE_DRIVER_BOOKING : [],
    FA_SAVE_DRIVER : [],
    FA_SAVE_ITEM_BOOKING : [],
    FA_SAVE_EXTRA_BOOKING : [],
    //TABLE->GROUP
    FA_GROUP_INFO : [],
    FA_GROUP_LIST : [],
    FA_ITEMS_OF_GROUPS : [],
    FA_SAVE_GROUP : [],
    FA_GROUP_EXT_LINK_LIST : [],

    //TABLE->BRAND
    FA_BRAND_INFO : [],
    FA_BRAND_LIST : [],
    FA_SAVE_BRAND : [],

    //TABLE->CAR
    FA_CAR_INFO : [],
    FA_CAR_LIST : [],
    FA_SAVE_CAR : [],

    FA_CAR_INSURER_LIST : [],
    FA_CAR_EXT_LINK_LIST : [],
    FA_ITEM_STATUSES_LIST : [],
    FA_FUEL_LIST : [],

    //TABLE->CAR MODEL
    FA_CAR_MODEL_INFO : [],
    FA_CAR_MODEL_LIST : [],
    FA_SAVE_CAR_MODEL : [],

    //TABLE->BIKE
    FA_BIKE_INFO : [],
    FA_BIKE_LIST : [],
    FA_SAVE_BIKE : [],

    //TABLE->BIKE MODEL
    FA_BIKE_MODEL_INFO : [],
    FA_BIKE_MODEL_LIST : [],
    FA_SAVE_BIKE_MODEL : [],

    //TABLE->SPORTSWEAR
    FA_SPORTSWEAR_INFO : [],
    FA_SPORTSWEAR_LIST : [],
    FA_SAVE_SPORTSWEAR : [],

    //TABLE->TENT
    FA_TENT_INFO : [],
    FA_TENT_LIST : [],
    FA_SAVE_TENT : [],

    //TABLE->SERVICE
    FA_SERVICE_INFO : [],
    FA_SERVICE_LIST : [],
    FA_SAVE_SERVICE : [],

    //TABLE -> AGENCY
    FA_AGENCY_INFO : [],
    FA_AGENCY_LIST : [],
    FA_SAVE_AGENCY : [],

    //TABLE -> STATION
    FA_STATION_INFO : [],
    FA_STATION_LIST : [],
    FA_SAVE_STATION : [],
    FA_LOCATION_LIST : [],
    FA_STATION_HOLIDAYS_LIST : [],

    //TABLE -> DEALER
    FA_DEALER_INFO : [],
    FA_SAVE_DEALER : [],
    FA_DEALER_LIST : [],

    //TABLE -> INSURER
    FA_INSURER_INFO : [],
    FA_SAVE_INSURER : [],
    FA_INSURER_LIST : [],

    //TABLE->DRIVER
    FA_DRIVER_LIST : [],
    FA_DRIVER_INFO_TABLE : [],

    //TABLE->FEE
    FA_FEE_INFO : [],
    FA_FEE_LIST : [],
    FA_SAVE_FEE : [],

    //TABLE->RATE
    FA_RATE_INFO : [],
    FA_RATE_LIST : [],
    FA_RATE_GROUP_LIST : [],
    FA_AGREEMENT_LIST : [],
    FA_SAVE_RATE : [],
    FA_SAVE_RATE_GROUP : [],

    FA_VALID_EQUATION : [],

    //TABLE->USER
    FA_USER_INFO : [],
    FA_USER_LIST : [],
    FA_SAVE_USER : [],

    //ADMIn->CONFIG
    FA_CONFIG_LIST : [],
    FA_SAVE_CONFIG : [],

    //ADMI->DASHBOARD WIDGETS -> CONFIG
    FA_DASH_WIDGETS_CONFIG_LIST : [],
    FA_SAVE_DASH_WIDGETS_CONFIG : [],

    //ADMI->DASHBOARD WIDGETS -> CONFIG
    FA_REPORT_TEMPLATES_LIST : [],
    FA_SAVE_REPORT_TEMPLATES : [],

    //ADMIn->TET
    FA_TET_INFO : [],
    FA_SAVE_TET : [],
    FA_ADICIONAL_INVOICE_INFO : [],

    //ADMIN->VVP
    FA_VVP_INFO : [],
    FA_SAVE_VVP : [],

    //ADMIN -> COMPANY
    FA_COMPANY_INFO : [],
    FA_SAVE_COMPANY : [],

    //FA_BOOKING_TO_CONTRACT : [],


    FA_FORM_LOCK_CONTROL : [],
};

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];
    
    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {

                open = state.isOpen;
                trigger = state.isTrigger;
                
                const triggerIndex = trigger.indexOf(action.menu.id);
                
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    // FLEETAUTO LIMITA O MENU A 3 NIVEIS COLLAPSE - SUB - ITEM
                    open = [...open];
                    open[1] = action.menu.id;

                    trigger = [...trigger];
                    trigger[1] = action.menu.id;
                    //open = [...open, action.menu.id];
                    //trigger = [...trigger, action.menu.id];
                }

            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;
                
                const triggerIndex = trigger.indexOf(action.menu.id);

                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return {...state};
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        case actionTypes.CHANGE_PRE_LAYOUT:
            return {
                ...state,
                preLayout: action.preLayout
            };
        case actionTypes.LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType,
                navBackColor: (action.layoutType === 'dark' && initialState.navBackColor === 'navbar-default') ? 'navbar-dark' : state.navBackColor,
                navBrandColor: (action.layoutType === 'dark' && initialState.navBrandColor === 'brand-default') ? 'brand-dark' : state.navBrandColor,
                navBackImage: initialState.navBackImage,
                headerBackColor: initialState.headerBackColor
            };
        case actionTypes.NAV_BACK_COLOR:
            return {
                ...state,
                navBackColor: action.navBackColor,
                navBackImage: initialState.navBackImage,
                navBrandColor: 'brand-default',
                layoutType: (state.layoutType === 'menu-light') ? 'menu-dark' : state.layoutType
            };
        case actionTypes.NAV_BACK_IMAGE:
            return {
                ...state,
                layoutType: 'menu-dark',
                navBackImage: action.navBackImage,
                navBrandColor: '',
                navBackColor: ''
            };
        case actionTypes.NAV_BRAND_COLOR:
            return {
                ...state,
                navBrandColor: action.navBrandColor
            };
        case actionTypes.HEADER_BACK_COLOR:
            return {
                ...state,
                headerBackColor: action.headerBackColor
            };
        case actionTypes.NAV_ICON_COLOR:
            return {
                ...state,
                navIconColor: !state.navIconColor
            };
        case actionTypes.RTL_LAYOUT:
            return {
                ...state,
                rtlLayout: !state.rtlLayout
            };
        case actionTypes.NAV_FIXED_LAYOUT:
            return {
                ...state,
                navFixedLayout: !state.navFixedLayout
            };
        case actionTypes.HEADER_FIXED_LAYOUT:
            return {
                ...state,
                headerFixedLayout: !state.headerFixedLayout,
                headerBackColor: (!state.headerFixedLayout && initialState.headerBackColor === 'header-default') ? 'header-blue' : state.headerBackColor,
                navBrandColor: (!state.headerFixedLayout) ? 'brand-default' : initialState.navBrandColor
            };
        case actionTypes.BOX_LAYOUT:
            return {
                ...state,
                boxLayout: !state.boxLayout
            };
        case actionTypes.LAYOUT6_BACKGROUND:
            return {
                ...state,
                layout6Background: action.value.layout6Background,
                layout6BackSize: action.value.layout6BackSize
            };
        case actionTypes.NAV_DROPDOWN_ICON:
            return {
                ...state,
                navDropdownIcon: action.navDropdownIcon,
            };
        case actionTypes.NAV_LIST_ICON:
            return {
                ...state,
                navListIcon: action.navListIcon,
            };
        case actionTypes.NAV_ACTIVE_LIST_COLOR:
            return {
                ...state,
                navActiveListColor: action.navActiveListColor,
            };
        case actionTypes.NAV_LIST_TITLE_COLOR:
            return {
                ...state,
                navListTitleColor: action.navListTitleColor,
            };
        case actionTypes.NAV_LIST_TITLE_HIDE:
            return {
                ...state,
                navListTitleHide: !state.navListTitleHide,
            };
        case actionTypes.CONFIG_BLOCK:
            return {
                ...state,
                configBlock: !state.configBlock,
            };
        case actionTypes.RESET:
            return {
                ...state,
                layout: initialState.layout,
                preLayout: initialState.preLayout,
                collapseMenu: initialState.collapseMenu,
                layoutType: initialState.layoutType,
                navIconColor: initialState.navIconColor,
                headerBackColor: initialState.headerBackColor,
                navBackColor: initialState.navBackColor,
                navBrandColor: initialState.navBrandColor,
                navBackImage: initialState.navBackImage,
                rtlLayout: initialState.rtlLayout,
                navFixedLayout: initialState.navFixedLayout,
                headerFixedLayout: initialState.headerFixedLayout,
                boxLayout: initialState.boxLayout,
                navDropdownIcon: initialState.navDropdownIcon,
                navListIcon: initialState.navListIcon,
                navActiveListColor: initialState.navActiveListColor,
                navListTitleColor: initialState.navListTitleColor,
                navListTitleHide: initialState.navListTitleHide,
                layout6Background : initialState.layout6Background,
                /* FLEETAUTO */

                //GLOBAL
                FA_AGENCIES_SELECT : initialState.FA_AGENCIES_SELECT,
                FA_COUNTRY_SELECT : initialState.FA_COUNTRY_SELECT,
                FA_AGENCIES_RATE_SELECT : initialState.FA_AGENCIES_RATE_SELECT,
                FA_STATIONS_SELECT : initialState.FA_STATIONS_SELECT,
                FA_GROUPS_SELECT: initialState.FA_GROUPS_SELECT,
                FA_OPERATOR_SELECT: initialState.FA_OPERATOR_SELECT,
                FA_CAR_FUEL_RANGE: initialState.FA_CAR_FUEL_RANGE,

                FA_DASHBOARD_BOOKINGS: initialState.FA_DASHBOARD_BOOKINGS,

                FA_OCCUPATION_CALENDAR_ITEMS : initialState.FA_OCCUPATION_CALENDAR_ITEMS,
                FA_BOOKINGS_CALENDAR_ITEMS : initialState.FA_BOOKINGS_CALENDAR_ITEMS,
                FA_BOOKINGS_GROUP_CALENDAR_ITEMS : initialState.FA_BOOKINGS_GROUP_CALENDAR_ITEMS,

                //CAR
                //FA_PICKUP_CAR_SAVE : initialState.FA_PICKUP_CAR_SAVE,
                //FA_RETURN_CAR_SAVE : initialState.FA_RETURN_CAR_SAVE,
                FA_BOOKING_CAR_SAVE : initialState.FA_BOOKING_CAR_SAVE,
                FA_CAR_CHANGE_MOTIVES : initialState.FA_CAR_CHANGE_MOTIVES,

                FA_CAR_PLATES_SELECT: initialState.FA_CAR_PLATES_SELECT,
                FA_CAR_PLATES_CHANGE_SELECT: initialState.FA_CAR_PLATES_CHANGE_SELECT,
                FA_AVAILABILITIES_LIST: initialState.FA_AVAILABILITIES_LIST,
                FA_CARS_LIST : initialState.FA_CARS_LIST,

                //DRIVER
                FA_DRIVER_SEARCH: initialState.FA_DRIVER_SEARCH,//search list 
                FA_DRIVER_HISTORY_SEARCH: initialState.FA_DRIVER_HISTORY_SEARCH,
                FA_DRIVER_INFO : initialState.FA_DRIVER_INFO,
                FA_BOOOKING_DRIVER_INFO: initialState.FA_BOOKING_DRIVER_INFO,
                FA_DRIVERS_LIST : initialState.FA_DRIVERS_LIST,
                FA_DRIVERS_HISTORY_LIST : initialState.FA_DRIVERS_HISTORY_LIST,
                FA_DRIVER_INFO_FOR_INVOICE : initialState.FA_DRIVER_INFO_FOR_INVOICE,

                //EXTRAS
                FA_EXTRAS_LIST : initialState.FA_EXTRAS_LIST,
                FA_EXTRAS_CALC : initialState.FA_EXTRAS_CALC,

                //PAYMENTS
                FA_PAYMENTS_LIST : initialState.FA_PAYMENTS_LIST,
                FA_PAYMENTS_SAVE : initialState.FA_PAYMENTS_SAVE,

                FA_MOVEMENTS_TYPES : initialState.FA_MOVEMENTS_TYPES,
                FA_PAYMENTS_TYPES : initialState.FA_PAYMENTS_TYPES,
                //TOLLS
                FA_BOOKING_TOLLS_LIST : initialState.FA_BOOKING_TOLLS_LIST,
                //BOOKINGS
                FA_BOOKINGS_LIST : initialState.FA_BOOKINGS_LIST,
                FA_BOOKING_INFO : initialState.FA_BOOKING_INFO,

                FA_BOOKING_STATUSES: initialState.FA_BOOKING_STATUSES,

                FA_RENTALAGREEMENTS_LIST : initialState.FA_RENTALAGREEMENTS_LIST,

                FA_FLIGHTS_CORRECTION_LIST : initialState.FA_FLIGHTS_CORRECTION_LIST,
                FA_FLIGHTS_CORRECTION : initialState.FA_FLIGHTS_CORRECTION,

                FA_BOOKING_TO_INVOICE_LIST : initialState.FA_BOOKING_TO_INVOICE_LIST,
                FA_BOOKING_INVOICED_SAVED_INFO : initialState.FA_BOOKING_INVOICED_SAVED_INFO,
                /*SAVE */
                FA_SAVE_BOOKING: initialState.FA_SAVE_BOOKING,
                FA_SAVE_CAR_BOOKING : initialState.FA_SAVE_CAR_BOOKING,
                FA_SAVE_DRIVER_BOOKING : initialState.FA_SAVE_DRIVER_BOOKING,
                FA_SAVE_DRIVER_ACTION : initialState.FA_SAVE_DRIVER_ACTION,
                FA_SAVE_DRIVER : initialState.FA_SAVE_DRIVER,
                FA_SAVE_ITEM_BOOKING : initialState.FA_SAVE_ITEM_BOOKING,
                FA_SAVE_EXTRA_BOOKING : initialState.FA_SAVE_EXTRA_BOOKING,

                //TABLE -> RATE
                FA_RATE_LIST : initialState.FA_RATE_LIST,
                FA_RATE_GROUP_LIST : initialState.FA_RATE_GROUP_LIST,
                FA_AGREEMENT_LIST : initialState.FA_AGREEMENT_LIST,
                FA_RATE_INFO : initialState.FA_RATE_INFO,
                FA_SAVE_RATE : initialState.FA_SAVE_RATE,
                FA_SAVE_RATE_GROUP : initialState.FA_SAVE_RATE_GROUP,

                FA_VALID_EQUATION : initialState.FA_VALID_EQUATION,

                //TABLE -> FEE
                FA_FEE_LIST : initialState.FA_FEE_LIST,
                FA_FEE_INFO : initialState.FA_FEE_INFO,
                FA_SAVE_FEE : initialState.FA_SAVE_FEE,

                //TABLE -> GROUP
                FA_GROUP_LIST : initialState.FA_GROUP_LIST,
                FA_ITEMS_OF_GROUPS : initialState.FA_ITEMS_OF_GROUPS,
                FA_GROUP_INFO : initialState.FA_GROUP_INFO,
                FA_SAVE_GROUP : initialState.FA_SAVE_GROUP,
                FA_GROUP_EXT_LINK_LIST : initialState.FA_GROUP_EXT_LINK_LIST,

                //TABLE -> BRAND
                FA_BRAND_LIST : initialState.FA_BRAND_LIST,
                FA_BRAND_INFO : initialState.FA_BRAND_INFO,
                FA_SAVE_BRAND : initialState.FA_SAVE_BRAND,

                //TABLE -> CAR_
                FA_CAR_LIST : initialState.FA_CAR_LIST,
                FA_CAR_INFO : initialState.FA_CAR_INFO,
                FA_SAVE_CAR : initialState.FA_SAVE_CAR,

                FA_CAR_INSURER_LIST : initialState.FA_CAR_INSURER_LIST,
                FA_CAR_EXT_LINK_LIST : initialState.FA_CAR_EXT_LINK_LIST,
                FA_ITEM_STATUSES_LIST : initialState.FA_ITEM_STATUSES_LIST,
                FA_FUEL_LIST : initialState.FA_FUEL_LIST,

                //TABLE -> CAR_MODEL
                FA_CAR_MODEL_LIST : initialState.FA_CAR_MODEL_LIST,
                FA_CAR_MODEL_INFO : initialState.FA_CAR_MODEL_INFO,
                FA_SAVE_CAR_MODEL : initialState.FA_SAVE_CAR_MODEL,

                //TABLE -> BIKE
                FA_BIKE_LIST : initialState.FA_BIKE_LIST,
                FA_BIKE_INFO : initialState.FA_BIKE_INFO,
                FA_SAVE_BIKE : initialState.FA_SAVE_BIKE,

                //TABLE -> BIKE_MODEL
                FA_BIKE_MODEL_LIST : initialState.FA_BIKE_MODEL_LIST,
                FA_BIKE_MODEL_INFO : initialState.FA_BIKE_MODEL_INFO,
                FA_SAVE_BIKE_MODEL : initialState.FA_SAVE_BIKE_MODEL,

                //TABLE -> SPORTSWEAR
                FA_SPORTSWEAR_LIST : initialState.FA_SPORTSWEAR_LIST,
                FA_SPORTSWEAR_INFO : initialState.FA_SPORTSWEAR_INFO,
                FA_SAVE_SPORTSWEAR : initialState.FA_SAVE_SPORTSWEAR,

                //TABLE -> TENT
                FA_TENT_LIST : initialState.FA_TENT_LIST,
                FA_TENT_INFO : initialState.FA_TENT_INFO,
                FA_SAVE_TENT : initialState.FA_SAVE_TENT,

                //TABLE -> SERVICE
                FA_SERVICE_LIST : initialState.FA_SERVICE_LIST,
                FA_SERVICE_INFO : initialState.FA_SERVICE_INFO,
                FA_SAVE_SERVICE : initialState.FA_SAVE_SERVICE,

                //TABLE -> AGENCY
                FA_AGENCY_LIST : initialState.FA_AGENCY_LIST,
                FA_AGENCY_INFO : initialState.FA_AGENCY_INFO,
                FA_SAVE_AGENCY : initialState.FA_SAVE_AGENCY,

                //TABLE -> STATION
                FA_STATION_LIST : initialState.FA_STATION_LIST,
                FA_STATION_INFO : initialState.FA_STATION_INFO,
                FA_SAVE_STATION : initialState.FA_SAVE_STATION,
                FA_LOCATION_LIST : initialState.FA_LOCATION_LIST,
                FA_STATION_HOLIDAYS_LIST : initialState.FA_STATION_HOLIDAYS_LIST,

                //TABLE -> DEALER
                FA_DEALER_LIST : initialState.FA_DEALER_LIST,
                FA_DEALER_INFO : initialState.FA_DEALER_INFO,
                FA_SAVE_DEALER : initialState.FA_SAVE_DEALER,

                //TABLE -> INSURER
                FA_INSURER_LIST : initialState.FA_INSURER_LIST,
                FA_INSURER_INFO : initialState.FA_INSURER_INFO,
                FA_SAVE_INSURER : initialState.FA_SAVE_INSURER,

                //TABLE -> DRIVER
                FA_DRIVER_LIST : initialState.FA_DRIVER_LIST,
                FA_DRIVER_INFO_TABLE : initialState.FA_DRIVER_INFO_TABLE,

                //TABLE -> USER
                FA_USER_LIST : initialState.FA_USER_LIST,
                FA_USER_INFO : initialState.FA_USER_INFO,
                FA_SAVE_USER : initialState.FA_SAVE_USER,

                //ADMIN-> CONFIG
                FA_CONFIG_INFO : initialState.FA_CONFIG_INFO,
                FA_SAVE_CONFIG : initialState.FA_SAVE_CONFIG,

                //ADMIN -> REPORT TEMPLATES
                FA_REPORT_TEMPLATES_LIST : initialState.FA_REPORT_TEMPLATES_LIST,
                FA_SAVE_REPORT_TEMPLATES : initialState.FA_SAVE_REPORT_TEMPLATES,

                //ADMIN-> TET
                FA_TET_INFO : initialState.FA_TET_INFO,
                FA_SAVE_TET : initialState.FA_SAVE_TET,
                FA_ADICIONAL_INVOICE_INFO : initialState.FA_ADICIONAL_INVOICE_INFO,

                //ADMIN-> VVP
                FA_VVP_INFO : initialState.FA_VVP_INFO,
                FA_SAVE_VVP : initialState.FA_SAVE_VVP,

                //ADMIN-> COMPANY
                FA_COMPANY_INFO : initialState.FA_COMPANY_INFO,
                FA_SAVE_COMPANY : initialState.FA_SAVE_COMPANY,

                FA_FORM_LOCK_CONTROL : initialState.FA_FORM_LOCK_CONTROL,

                //FA_BOOKING_TO_CONTRACT: initialState.FA_BOOKING_TO_CONTRACT,
            };

        /* FLEETAUTO GENERAL */

        case actionTypes.FA_FORM_LOCK_CONTROL:
            return {
                ...state,
                FA_FORM_LOCK_CONTROL: action.formLockControl,
            };

        case actionTypes.FA_CLEAR_FORM_LOCK_CONTROL:
            return {
                ...state,
                FA_FORM_LOCK_CONTROL: [],
            };

        // ----------
        case actionTypes.FA_AGENCIES:
            return {
                ...state,
                FA_AGENCIES_SELECT: action.agenciesSelect,
            };
        case actionTypes.FA_AGENCIES_RATE:
            return {
                ...state,
                FA_AGENCIES_RATE_SELECT: action.agenciesRateSelect,
            };
        case actionTypes.FA_COUNTRY_SELECT:
            return {
                ...state,
                FA_COUNTRY_SELECT: action.countrySelect,
            };
        case actionTypes.FA_STATIONS:
            return {
                ...state,
                FA_STATIONS_SELECT: action.stationsSelect,
            };
        case actionTypes.FA_GROUPS:
            return {
                ...state,
                FA_GROUPS_SELECT: action.groupsSelect,
            };
        case actionTypes.FA_CAR_PLATES:
            return {
                ...state,
                FA_CAR_PLATES_SELECT: action.carPlatesSelect,
            };
        case actionTypes.FA_CAR_PLATES_CHANGE:
            return {
                ...state,
                FA_CAR_PLATES_CHANGE_SELECT: action.carPlatesChangeSelect,
            };
        case actionTypes.FA_OPERATOR_SELECT:
            return {
                ...state,
                FA_OPERATOR_SELECT: action.operatorSelect,
            };
        case actionTypes.FA_DRIVER_SEARCH:
            return {
                ...state,
                FA_DRIVER_SEARCH: action.driverSearch,
            };
        case actionTypes.FA_DRIVER_HISTORY_SEARCH:
            return {
                ...state,
                FA_DRIVER_HISTORY_SEARCH: action.driverHistorySearch,
            };
        case actionTypes.FA_AVAILABILITIES_LIST:
            return {
                ...state,
                FA_AVAILABILITIES_LIST: action.carBookingAvailabilities,
            };
        case actionTypes.FA_BOOKING_TOLLS_LIST:
            return {
                ...state,
                FA_BOOKING_TOLLS_LIST: action.bookingTollsInfo,
            };
        case actionTypes.FA_DRIVERS_LIST:
            return {
                ...state,
                FA_DRIVERS_LIST: action.driversList,
            };
        case actionTypes.FA_DRIVER_INFO:
            return {
                ...state,
                FA_DRIVER_INFO: action.driverInfo,
                FA_BOOKING_DRIVER_INFO: action.bookingDriverInfo,
            };
        case actionTypes.FA_DRIVER_INFO_FOR_INVOICE:
            return {
                ...state,
                FA_DRIVER_INFO_FOR_INVOICE: action.invoiceInfo,
            };
        case actionTypes.FA_CLEAR_DRIVER_INFO_FOR_INVOICE:
            return {
                ...state,
                FA_DRIVER_INFO_FOR_INVOICE: [],
            };
        case actionTypes.FA_DRIVERS_HISTORY_LIST:
            return {
                ...state,
                FA_DRIVERS_HISTORY_LIST: action.driversHistoryList,
            };

        case actionTypes.FA_CARS_LIST:
            return {
                ...state,
                FA_CARS_LIST: action.carsList,
            };
        case actionTypes.FA_EXTRAS_LIST://list of extras for booking
            return {
                ...state,
                FA_EXTRAS_LIST: action.extrasListInfo,
            };
        case actionTypes.FA_EXTRA_CALC://list of extras for booking
            return {
                ...state,
                FA_EXTRA_CALC: action.extraInfo,
            };
        case actionTypes.FA_CAR_FUEL_RANGE:
            return {
                ...state,
                FA_CAR_FUEL_RANGE: action.carFuelRange,
            };
        //PAYMENTS
        case actionTypes.FA_PAYMENTS_LIST:
            return {
                ...state,
                FA_PAYMENTS_LIST: action.paymentsList,
            };
        case actionTypes.FA_PAYMENTS_SAVE:
            return {
                ...state,
                FA_PAYMENTS_SAVE: action.paymentSave,
            };
        case actionTypes.FA_MOVEMENTS_TYPES:
            return {
                ...state,
                FA_MOVEMENTS_TYPES: action.movementsTypes,
            };
        case actionTypes.FA_PAYMENTS_TYPES:
            return {
                ...state,
                FA_PAYMENTS_TYPES: action.paymentsTypes,
            };
        case actionTypes.FA_BOOKING_STATUSES:
            return {
                ...state,
                FA_BOOKING_STATUSES: action.bookingStatuses,
            };
        /**SAVE ACTIONS */
        case actionTypes.FA_SAVE_BOOKING:
            return {
                ...state,
                FA_SAVE_BOOKING: action.bookingSavedInfo,
            };
        case actionTypes.FA_BOOKING_CAR_SAVE:
            return {
                ...state,
                FA_BOOKING_CAR_SAVE: action.bookingCarSavedInfo,
            };

        case actionTypes.FA_CAR_CHANGE_MOTIVES:
            return {
                ...state,
                FA_CAR_CHANGE_MOTIVES: action.carChangeMotives,
            };
        /*case actionTypes.FA_RETURN_CAR_SAVE:
            return {
                ...state,
                FA_RETURN_CAR_SAVE: action.carReturnSavedInfo,
            };*/
        case actionTypes.FA_SAVE_DRIVER_BOOKING://to assign driver to booking
            return {
                ...state,
                FA_SAVE_DRIVER_BOOKING: action.driverBookingSavedInfo,
            };
        case actionTypes.FA_SAVE_DRIVER_ACTION://to set main, set invoice and delete
            return {
                ...state,
                FA_SAVE_DRIVER_ACTION: action.driverActionInfo,
            };
        case actionTypes.FA_SAVE_DRIVER:
            return {
                ...state,
                FA_SAVE_DRIVER: action.driverSavedInfo,
            };
        case actionTypes.FA_SAVE_EXTRA_BOOKING:
            return {
                ...state,
                FA_SAVE_EXTRA_BOOKING: action.extraBookingSavedInfo,
            };
        case actionTypes.FA_SAVE_ITEM_BOOKING:
            return {
                ...state,
                FA_SAVE_ITEM_BOOKING: action.itemBookingSavedInfo,
            };
        case actionTypes.FA_DASHBOARD_BOOKINGS:
            return {
                ...state,
                FA_DASHBOARD_BOOKINGS: action.dashboardBookings,
            };
        case actionTypes.FA_OCCUPATION_CALENDAR_ITEMS:
            return {
                ...state,
                FA_OCCUPATION_CALENDAR_ITEMS: action.occupationCalendarItems,
            };
        case actionTypes.FA_BOOKINGS_CALENDAR_ITEMS:
            return {
                ...state,
                FA_BOOKINGS_CALENDAR_ITEMS: action.bookingsCalendarItems,
            };
        case actionTypes.FA_BOOKINGS_GROUP_CALENDAR_ITEMS:
            return {
                ...state,
                FA_BOOKINGS_GROUP_CALENDAR_ITEMS: action.bookingsGroupCalendarItems,
            };
            

        /*case actionTypes.FA_BOOKING_TO_CONTRACT:
            return {
                ...state,
                FA_BOOKING_TO_CONTRACT: action.bookingToContractInfo,
            };*/
/**CLEAR DATA */
        //DASHBOARDS 
        case actionTypes.FA_CLEAR_ALL_DASHBOARD_SERVICES:
            return {
                ...state,
                FA_OPERATOR_SELECT: [],
                FA_AGENCIES_SELECT: [],
                FA_COUNTRY_SELECT: [],
                FA_STATIONS_SELECT: [],
                FA_GROUPS_SELECT: [],
                FA_CAR_FUEL_RANGE: [],
                FA_MOVEMENTS_TYPES: [],
                FA_PAYMENTS_TYPES: [],
            };
        //ALL OF BOOKING FORM
        case actionTypes.FA_CLEAR_ALL_BOOKING_DATA:
            return {
                ...state,
                FA_AGENCIES_RATE_SELECT : [],
                FA_CAR_PLATES_SELECT: [],
                FA_CAR_PLATES_CHANGE_SELECT: [],
                FA_BOOKING_CAR_SAVE: [],
                FA_CAR_CHANGE_MOTIVES: [],
                FA_DRIVER_INFO: [],
                FA_BOOKING_DRIVER_INFO: [],
                FA_SAVE_DRIVER_ACTION: [],
                FA_DRIVER_INFO_FOR_INVOICE: [],
                FA_DRIVERS_LIST: [],
                FA_DRIVERS_HISTORY_LIST: [],
                FA_DRIVER_SEARCH: [],
                FA_DRIVER_HISTORY_SEARCH: [],
                FA_CARS_LIST: [],
                FA_EXTRAS_LIST: [],
                FA_EXTRAS_CALC: [],
                FA_PAYMENTS_LIST: [],
                FA_PAYMENTS_SAVE: [],
                FA_BOOKING_TOLLS_LIST: [],
                FA_AVAILABILITIES_LIST: [],
                FA_BOOKINGS_LIST : [],
                FA_BOOKING_INFO : [],
                //FOR SAVE ACTIONS ON BOOKING FORM
                FA_SAVE_BOOKING : [],
                FA_SAVE_CAR_BOOKING : [],
                FA_SAVE_DRIVER_BOOKING : [],
                FA_SAVE_DRIVER : [],
                FA_SAVE_ITEM_BOOKING : [],
                FA_SAVE_EXTRA_BOOKING : [],
            };
        
        //ALL OF BOOKING
        case actionTypes.FA_CLEAR_BOOKING_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_BOOKING: [],
            };
        //CAR
       /* case actionTypes.FA_CLEAR_CAR_PICKUP_INFO:
            return {
                ...state,
                FA_PICKUP_CAR_SAVE: [],
            };*/
        case actionTypes.FA_CLEAR_BOOKING_CAR_INFO:
            return {
                ...state,
                FA_BOOKING_CAR_SAVE: [],
            };
        /*case actionTypes.FA_CLEAR_CAR_CHANGE_INFO://CLEAR OF 2 FORM'S
            return {
                ...state,
                FA_BOOKING_CAR_SAVE: []
                //FA_PICKUP_CAR_SAVE: [],
                //FA_RETURN_CAR_SAVE: [],
            };*/
        //DRIVER
        case actionTypes.FA_CLEAR_DRIVERS_LIST:
            return {
                ...state,
                FA_DRIVERS_LIST: [],//Clear all data
            };
        case actionTypes.FA_CLEAR_EXTRA_INFO:
            return {
                ...state,
                FA_EXTRA_CALC: [],//Clear all data
            };
        case actionTypes.FA_CLEAR_DRIVER_SEARCH:
            return {
                ...state,
                FA_DRIVER_SEARCH: [],//Clear all data
                FA_DRIVER_HISTORY_SEARCH: [],//Clear all data
            };
        case actionTypes.FA_CLEAR_DRIVER_HISTORY_SEARCH:
            return {
                ...state,
                FA_DRIVER_HISTORY_SEARCH: [],//Clear all data
            };
        case actionTypes.FA_CLEAR_DRIVER_INFO:
            return {
                ...state,
                FA_SAVE_DRIVER: [],//Clear all data
            };
        case actionTypes.FA_CLEAR_DRIVER_INFO_2:
            return {
                ...state,
                FA_SAVE_DRIVER_BOOKING: [],//Clear all data
                FA_BOOKING_DRIVER_INFO: [],//Clear data of connection of driver to booking table
            };
        case actionTypes.FA_CLEAR_DRIVER_ACTION://to assign driver to booking
            return {
                ...state,
                FA_SAVE_DRIVER_ACTION: [],
            };
        case actionTypes.FA_CLEAR_PAYMENT_SAVE:
            return {
                ...state,
                FA_PAYMENTS_SAVE: [],
            };
        /* FLEETAUTO DOCUMENTS */
        case actionTypes.FA_BOOKING_INFO:
            return {
                ...state,
                FA_BOOKING_INFO: action.bookingInfo,
            };
        case actionTypes.FA_BOOKINGS_LIST:
            return {
                ...state,
                FA_BOOKINGS_LIST: action.bookingsList,
            };
        case actionTypes.FA_CLEAR_BOOKINGS_LIST:
            return {
                ...state,
                FA_BOOKINGS_LIST: [],
            };

        case actionTypes.FA_RENTALAGREEMENTS:
            return {
                ...state,
                FA_RENTALAGREEMENTS_LIST: action.rentalagreements_list,
            };
        //FLIGHTS
        case actionTypes.FA_FLIGHTS_CORRECTION_LIST:
            return {
                ...state,
                FA_FLIGHTS_CORRECTION_LIST: action.flightsCorrectionList,
            };
        case actionTypes.FA_FLIGHTS_CORRECTION:
            return {
                ...state,
                FA_FLIGHTS_CORRECTION: action.flightsSavedCorrection,
            };
        case actionTypes.FA_FLIGHTS_CORRECTION_CLEAR:
            return {
                ...state,
                FA_FLIGHTS_CORRECTION: []
            };
        case actionTypes.FA_FLIGHTS_CORRECTION_LIST_CLEAR:
            return {
                ...state,
                FA_FLIGHTS_CORRECTION_LIST: []
            };
            //INVOICE BOOKING
        case actionTypes.FA_BOOKING_TO_INVOICE_LIST:
            return {
                ...state,
                FA_BOOKING_TO_INVOICE_LIST: action.bookingToInvoiceList,
            };
        case actionTypes.FA_BOOKING_INVOICED_SAVED_INFO:
            return {
                ...state,
                FA_BOOKING_INVOICED_SAVED_INFO: action.bookingInvoicedSavedInfo,
            };
        case actionTypes.FA_CLEAR_BOOKING_INVOICED_SAVED_INFO:
            return {
                ...state,
                FA_CLEAR_BOOKING_INVOICED_SAVED_INFO: []
            };
        case actionTypes.FA_CLEAR_BOOKING_TO_INVOICE_LIST:
            return {
                ...state,
                FA_CLEAR_BOOKING_TO_INVOICE_LIST: []
            };
        /**
         *  TABLE -> GROUP
         * */
        case actionTypes.FA_GROUP_LIST:
            return {
                ...state,
                FA_GROUP_LIST: action.groupList,
            };
        case actionTypes.FA_ITEMS_OF_GROUPS:
            return {
                ...state,
                FA_ITEMS_OF_GROUPS: action.itemsSelect,
            };
        case actionTypes.FA_GROUP_LIST_CLEAR:
            return {
                ...state,
                FA_GROUP_LIST: []
            };
        case actionTypes.FA_GROUP_INFO:
            return {
                ...state,
                FA_GROUP_INFO: action.groupInfo,
            };
        case actionTypes.FA_SAVE_GROUP:
            return {
                ...state,
                FA_SAVE_GROUP: action.groupSavedInfo,
            };
        case actionTypes.FA_CLEAR_GROUP_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_GROUP: [],
            };
        case actionTypes.FA_CLEAR_ALL_GROUP_FORM_DATA:
            return {
                ...state,
                FA_SAVE_GROUP: [],
                FA_GROUP_INFO: [],
                FA_GROUP_EXT_LINK_LIST : []
            };
        case actionTypes.FA_GROUP_EXT_LINK_LIST:
            return {
                ...state,
                FA_GROUP_EXT_LINK_LIST: action.groupExtLinkList,
            };

        /**
         *  TABLE -> RATE
         * */
        case actionTypes.FA_RATE_LIST:
            return {
                ...state,
                FA_RATE_LIST: action.rateList,
            };
        case actionTypes.FA_RATE_GROUP_LIST:
            return {
                ...state,
                FA_RATE_GROUP_LIST: action.rateGroupList,
            };
        case actionTypes.FA_AGREEMENT_LIST:
            return {
                ...state,
                FA_AGREEMENT_LIST: action.agreementList,
            };
        case actionTypes.FA_RATE_LIST_CLEAR:
            return {
                ...state,
                FA_RATE_LIST: []
            };
        case actionTypes.FA_RATE_GROUP_LIST_CLEAR:
            return {
                ...state,
                FA_RATE_GROUP_LIST: []
            };
        case actionTypes.FA_RATE_INFO:
            return {
                ...state,
                FA_RATE_INFO: action.rateInfo,
            };
        case actionTypes.FA_SAVE_RATE:
            return {
                ...state,
                FA_SAVE_RATE: action.rateSavedInfo,
            };
        case actionTypes.FA_CLEAR_RATE_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_RATE: [],
            };
        case actionTypes.FA_RATE_GROUP_INFO:
            return {
                ...state,
                FA_RATE_GROUP_INFO: action.rateGroupInfo,
            };
        case actionTypes.FA_CLEAR_RATE_GROUP_INFO:
            return {
                ...state,
                FA_RATE_GROUP_INFO: [],
            };
        case actionTypes.FA_SAVE_RATE_GROUP:
            return {
                ...state,
                FA_SAVE_RATE_GROUP: action.rateGroupSavedInfo,
            };
        case actionTypes.FA_CLEAR_RATE_GROUP_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_RATE_GROUP: [],
            };
        case actionTypes.FA_VALID_EQUATION:
            return {
                ...state,
                FA_VALID_EQUATION: action.validEquation,
            };
        case actionTypes.FA_CLEAR_VALID_EQUATION:
            return {
                ...state,
                FA_VALID_EQUATION: [],
            };
        case actionTypes.FA_CLEAR_ALL_RATE_FORM_DATA:
            return {
                ...state,
                FA_SAVE_RATE: [],
                FA_RATE_INFO: [],
                FA_SAVE_RATE_GROUP: [],
                FA_RATE_GROUP_INFO: [],
                FA_VALID_EQUATION: [],
            };

        /**
         *  TABLE -> FEE
         * */
        case actionTypes.FA_FEE_LIST:
            return {
                ...state,
                FA_FEE_LIST: action.feeList,
            };
        case actionTypes.FA_FEE_LIST_CLEAR:
            return {
                ...state,
                FA_FEE_LIST: []
            };
        case actionTypes.FA_FEE_INFO:
            return {
                ...state,
                FA_FEE_INFO: action.feeInfo,
            };
        case actionTypes.FA_SAVE_FEE:
            return {
                ...state,
                FA_SAVE_FEE: action.feeSavedInfo,
            };
        case actionTypes.FA_CLEAR_FEE_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_FEE: [],
            };
        case actionTypes.FA_CLEAR_ALL_FEE_FORM_DATA:
            return {
                ...state,
                FA_SAVE_FEE: [],
                FA_FEE_INFO: []
            };
        /**
         *  TABLE -> BRAND
         * */
        case actionTypes.FA_BRAND_LIST:
            return {
                ...state,
                FA_BRAND_LIST: action.brandList,
            };
        case actionTypes.FA_BRAND_LIST_CLEAR:
            return {
                ...state,
                FA_BRAND_LIST: []
            };
        case actionTypes.FA_BRAND_INFO:
            return {
                ...state,
                FA_BRAND_INFO: action.brandInfo,
            };
        case actionTypes.FA_SAVE_BRAND:
            return {
                ...state,
                FA_SAVE_BRAND: action.brandSavedInfo,
            };
        case actionTypes.FA_CLEAR_BRAND_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_BRAND: [],
            };
        case actionTypes.FA_CLEAR_ALL_BRAND_FORM_DATA:
            return {
                ...state,
                FA_SAVE_BRAND: [],
                FA_BRAND_INFO: []
            };
        /**
         *  TABLE -> CAR MODEL
         * */
        case actionTypes.FA_CAR_MODEL_LIST:
            return {
                ...state,
                FA_CAR_MODEL_LIST: action.carModelList,
            };
        case actionTypes.FA_CAR_MODEL_LIST_CLEAR:
            return {
                ...state,
                FA_CAR_MODEL_LIST: []
            };
        case actionTypes.FA_CAR_MODEL_INFO:
            return {
                ...state,
                FA_CAR_MODEL_INFO: action.carModelInfo,
            };
        case actionTypes.FA_SAVE_CAR_MODEL:
            return {
                ...state,
                FA_SAVE_CAR_MODEL: action.carModelSavedInfo,
            };
        case actionTypes.FA_CLEAR_CAR_MODEL_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_CAR_MODEL: [],
            };
        case actionTypes.FA_CLEAR_ALL_CAR_MODEL_FORM_DATA:
            return {
                ...state,
                FA_SAVE_CAR_MODEL: [],
                FA_CAR_MODEL_INFO: []
            };

        /**
         *  TABLE -> CAR
         * */
        case actionTypes.FA_CAR_LIST:
            return {
                ...state,
                FA_CAR_LIST: action.carList,
            };
        case actionTypes.FA_CAR_LIST_CLEAR:
            return {
                ...state,
                FA_CAR_LIST: []
            };
        case actionTypes.FA_CAR_INSURER_LIST:
            return {
                ...state,
                FA_CAR_INSURER_LIST: action.carInsurerList,
            };
        case actionTypes.FA_CAR_EXT_LINK_LIST:
            return {
                ...state,
                FA_CAR_EXT_LINK_LIST: action.carExtLinkList,
            };
        case actionTypes.FA_CAR_INFO:
            return {
                ...state,
                FA_CAR_INFO: action.carInfo,
            };
        case actionTypes.FA_SAVE_CAR:
            return {
                ...state,
                FA_SAVE_CAR: action.carSavedInfo,
            };
        case actionTypes.FA_CLEAR_CAR_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_CAR: [],
            };
        case actionTypes.FA_CLEAR_ALL_CAR_FORM_DATA:
            return {
                ...state,
                FA_SAVE_CAR: [],
                FA_CAR_INFO: [],
                //FA_DEALER_LIST : [],
                FA_CAR_INSURER_LIST : [],
                FA_CAR_EXT_LINK_LIST : []
            };

        case actionTypes.FA_ITEM_STATUSES_LIST:
            return {
                ...state,
                FA_ITEM_STATUSES_LIST: action.itemStatusesList,
            };
        case actionTypes.FA_FUEL_LIST:
            return {
                ...state,
                FA_FUEL_LIST: action.fuelList,
            };

        /**
         *  TABLE -> BIKE
         * */
        case actionTypes.FA_BIKE_LIST:
            return {
                ...state,
                FA_BIKE_LIST: action.bikeList,
            };
        case actionTypes.FA_BIKE_LIST_CLEAR:
            return {
                ...state,
                FA_BIKE_LIST: []
            };
        case actionTypes.FA_BIKE_INFO:
            return {
                ...state,
                FA_BIKE_INFO: action.bikeInfo,
            };
        case actionTypes.FA_SAVE_BIKE:
            return {
                ...state,
                FA_SAVE_BIKE: action.bikeSavedInfo,
            };
        case actionTypes.FA_CLEAR_BIKE_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_BIKE: [],
            };
        case actionTypes.FA_CLEAR_ALL_BIKE_FORM_DATA:
            return {
                ...state,
                FA_SAVE_BIKE: [],
                FA_BIKE_INFO: [],
                FA_DEALER_LIST: []
            };

        /**
         *  TABLE -> BIKE MODEL
         * */
        case actionTypes.FA_BIKE_MODEL_LIST:
            return {
                ...state,
                FA_BIKE_MODEL_LIST: action.bikeModelList,
            };
        case actionTypes.FA_BIKE_MODEL_LIST_CLEAR:
            return {
                ...state,
                FA_BIKE_MODEL_LIST: []
            };
        case actionTypes.FA_BIKE_MODEL_INFO:
            return {
                ...state,
                FA_BIKE_MODEL_INFO: action.bikeModelInfo,
            };
        case actionTypes.FA_SAVE_BIKE_MODEL:
            return {
                ...state,
                FA_SAVE_BIKE_MODEL: action.bikeModelSavedInfo,
            };
        case actionTypes.FA_CLEAR_BIKE_MODEL_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_BIKE_MODEL: [],
            };
        case actionTypes.FA_CLEAR_ALL_BIKE_MODEL_FORM_DATA:
            return {
                ...state,
                FA_SAVE_BIKE_MODEL: [],
                FA_BIKE_MODEL_INFO: []
            };

        /**
         *  TABLE -> SPORTSWEAR
         * */
        case actionTypes.FA_SPORTSWEAR_LIST:
            return {
                ...state,
                FA_SPORTSWEAR_LIST: action.sportswearList,
            };
        case actionTypes.FA_SPORTSWEAR_LIST_CLEAR:
            return {
                ...state,
                FA_SPORTSWEAR_LIST: []
            };
        case actionTypes.FA_SPORTSWEAR_INFO:
            return {
                ...state,
                FA_SPORTSWEAR_INFO: action.sportswearInfo,
            };
        case actionTypes.FA_SAVE_SPORTSWEAR:
            return {
                ...state,
                FA_SAVE_SPORTSWEAR: action.sportswearSavedInfo,
            };
        case actionTypes.FA_CLEAR_SPORTSWEAR_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_SPORTSWEAR: [],
            };
        case actionTypes.FA_CLEAR_ALL_SPORTSWEAR_FORM_DATA:
            return {
                ...state,
                FA_SAVE_SPORTSWEAR: [],
                FA_SPORTSWEAR_INFO: [],
                FA_DEALER_LIST: []
            };
        
        /**
         *  TABLE -> TENT
         * */
        case actionTypes.FA_TENT_LIST:
            return {
                ...state,
                FA_TENT_LIST: action.tentList,
            };
        case actionTypes.FA_TENT_LIST_CLEAR:
            return {
                ...state,
                FA_TENT_LIST: []
            };
        case actionTypes.FA_TENT_INFO:
            return {
                ...state,
                FA_TENT_INFO: action.tentInfo,
            };
        case actionTypes.FA_SAVE_TENT:
            return {
                ...state,
                FA_SAVE_TENT: action.tentSavedInfo,
            };
        case actionTypes.FA_CLEAR_TENT_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_TENT: [],
            };
        case actionTypes.FA_CLEAR_ALL_TENT_FORM_DATA:
            return {
                ...state,
                FA_SAVE_TENT: [],
                FA_TENT_INFO: [],
                FA_DEALER_LIST: []
            };

        /**
         *  TABLE -> USER
         * */
        case actionTypes.FA_USER_LIST:
            return {
                ...state,
                FA_USER_LIST: action.userList,
            };
        case actionTypes.FA_USER_LIST_CLEAR:
            return {
                ...state,
                FA_USER_LIST: []
            };
        case actionTypes.FA_USER_INFO:
            return {
                ...state,
                FA_USER_INFO: action.userInfo,
            };
        case actionTypes.FA_SAVE_USER:
            return {
                ...state,
                FA_SAVE_USER: action.userSavedInfo,
            };
        case actionTypes.FA_CLEAR_USER_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_USER: [],
            };
        case actionTypes.FA_CLEAR_ALL_USER_FORM_DATA:
            return {
                ...state,
                FA_SAVE_USER: [],
                FA_USER_INFO: [],
            };
        /**
         *  TABLE -> SERVICE
         * */
        case actionTypes.FA_SERVICE_LIST:
            return {
                ...state,
                FA_SERVICE_LIST: action.serviceList,
            };
        case actionTypes.FA_SERVICE_LIST_CLEAR:
            return {
                ...state,
                FA_SERVICE_LIST: []
            };
        case actionTypes.FA_SERVICE_INFO:
            return {
                ...state,
                FA_SERVICE_INFO: action.serviceInfo,
            };
        case actionTypes.FA_SAVE_SERVICE:
            return {
                ...state,
                FA_SAVE_SERVICE: action.serviceSavedInfo,
            };
        case actionTypes.FA_CLEAR_SERVICE_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_SERVICE: [],
            };
        case actionTypes.FA_CLEAR_ALL_SERVICE_FORM_DATA:
            return {
                ...state,
                FA_SAVE_SERVICE: [],
                FA_SERVICE_INFO: [],
                FA_DEALER_LIST:[],
            };
        /**
         *  TABLE -> DRIVER
         * */
        case actionTypes.FA_DRIVER_LIST:
            return {
                ...state,
                FA_DRIVER_LIST: action.driverList,
            };
        case actionTypes.FA_DRIVER_LIST_CLEAR:
            return {
                ...state,
                FA_DRIVER_LIST: []
            };
        case actionTypes.FA_DRIVER_INFO_TABLE:
            return {
                ...state,
                FA_DRIVER_INFO_TABLE: action.driverInfoTable,
            };
        // case actionTypes.FA_SAVE_DRIVER:
        //     return {
        //         ...state,
        //         FA_SAVE_DRIVER: action.driverSavedInfo,
        //     };
        case actionTypes.FA_CLEAR_DRIVER_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_DRIVER: [],
            };
        case actionTypes.FA_CLEAR_ALL_DRIVER_FORM_DATA:
            return {
                ...state,
                FA_SAVE_DRIVER: [],
                FA_DRIVER_INFO_TABLE: []
            };
        /**
         *  TABLE -> AGENCY
         * */
        case actionTypes.FA_AGENCY_LIST:
            return {
                ...state,
                FA_AGENCY_LIST: action.agencyList,
            };
        case actionTypes.FA_AGENCY_LIST_CLEAR:
            return {
                ...state,
                FA_AGENCY_LIST: []
            };
        case actionTypes.FA_AGENCY_INFO:
            return {
                ...state,
                FA_AGENCY_INFO: action.agencyInfo,
            };
        case actionTypes.FA_SAVE_AGENCY:
            return {
                ...state,
                FA_SAVE_AGENCY: action.agencySavedInfo,
            };
        case actionTypes.FA_CLEAR_AGENCY_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_AGENCY: [],
            };
        case actionTypes.FA_CLEAR_ALL_AGENCY_FORM_DATA:
            return {
                ...state,
                FA_SAVE_AGENCY: [],
                FA_AGENCY_INFO: []
            };

        /**
         *  TABLE -> STATION
         * */
        case actionTypes.FA_STATION_LIST:
            return {
                ...state,
                FA_STATION_LIST: action.stationList,
            };
        case actionTypes.FA_STATION_LIST_CLEAR:
            return {
                ...state,
                FA_STATION_LIST: []
            };
        case actionTypes.FA_STATION_INFO:
            return {
                ...state,
                FA_STATION_INFO: action.stationInfo,
            };
        case actionTypes.FA_SAVE_STATION:
            return {
                ...state,
                FA_SAVE_STATION: action.stationSavedInfo,
            };
        case actionTypes.FA_CLEAR_STATION_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_STATION: [],
            };
        case actionTypes.FA_CLEAR_ALL_STATION_FORM_DATA:
            return {
                ...state,
                FA_SAVE_STATION: [],
                FA_STATION_INFO: [],
                FA_STATION_HOLIDAYS_LIST: [],
            };
        case actionTypes.FA_LOCATION_LIST:
            return {
                ...state,
                FA_LOCATION_LIST: action.locationList,
            };
        case actionTypes.FA_LOCATION_LIST_CLEAR:
            return {
                ...state,
                FA_LOCATION_LIST: []
            };
        case actionTypes.FA_STATION_HOLIDAYS_LIST:
            return {
                ...state,
                FA_STATION_HOLIDAYS_LIST: action.stationHolidaysList,
            };
        /**
         *  TABLE -> DEALER
         * */
        case actionTypes.FA_DEALER_LIST:
            return {
                ...state,
                FA_DEALER_LIST: action.dealerList,
            };
        case actionTypes.FA_DEALER_LIST_CLEAR:
            return {
                ...state,
                FA_DEALER_LIST: []
            };
        case actionTypes.FA_DEALER_INFO:
            return {
                ...state,
                FA_DEALER_INFO: action.dealerInfo,
            };
        case actionTypes.FA_SAVE_DEALER:
            return {
                ...state,
                FA_SAVE_DEALER: action.dealerSavedInfo
            };
        case actionTypes.FA_CLEAR_DEALER_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_DEALER: []
            };
        case actionTypes.FA_CLEAR_ALL_DEALER_FORM_DATA:
            return {
                ...state,
                FA_SAVE_DEALER: [],
                FA_DEALER_INFO: []
            };

        /**
         *  TABLE -> INSURER
         * */
        case actionTypes.FA_INSURER_LIST:
            return {
                ...state,
                FA_INSURER_LIST: action.insurerList,
            };
        case actionTypes.FA_INSURER_LIST_CLEAR:
            return {
                ...state,
                FA_INSURER_LIST: []
            };
        case actionTypes.FA_INSURER_INFO:
            return {
                ...state,
                FA_INSURER_INFO: action.insurerInfo,
            };
        case actionTypes.FA_SAVE_INSURER:
            return {
                ...state,
                FA_SAVE_INSURER: action.insurerSavedInfo
            };
        case actionTypes.FA_CLEAR_INSURER_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_INSURER: []
            };
        case actionTypes.FA_CLEAR_ALL_INSURER_FORM_DATA:
            return {
                ...state,
                FA_SAVE_INSURER: [],
                FA_INSURER_INFO: []
            };

        /**
         *  ADMIN -> CONFIG
         * */
        case actionTypes.FA_CONFIG_LIST:
            return {
                ...state,
                FA_CONFIG_LIST: action.configList,
            };
        case actionTypes.FA_CONFIG_LIST_CLEAR:
            return {
                ...state,
                FA_CONFIG_LIST: []
            };
        case actionTypes.FA_SAVE_CONFIG:
            return {
                ...state,
                FA_SAVE_CONFIG: action.configSavedInfo,
            };
        case actionTypes.FA_CLEAR_CONFIG_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_CONFIG: [],
            };
        case actionTypes.FA_CLEAR_ALL_CONFIG_FORM_DATA:
            return {
                ...state,
                FA_SAVE_CONFIG: [],
                FA_CONFIG_LIST: []
            };

        /**
         *  ADMIN -> CUSTOMIZE -> DASHBOARD
         * */
        case actionTypes.FA_DASH_WIDGETS_CONFIG_LIST:
            return {
                ...state,
                FA_DASH_WIDGETS_CONFIG_LIST: action.dashboardWidgetsConfigList,
            };
        case actionTypes.FA_DASH_WIDGETS_CONFIG_LIST_CLEAR:
            return {
                ...state,
                FA_DASH_WIDGETS_CONFIG_LIST: []
            };
        case actionTypes.FA_SAVE_DASH_WIDGETS_CONFIG:
            return {
                ...state,
                FA_SAVE_DASH_WIDGETS_CONFIG: action.dashboardWidgetsConfigSavedInfo,
            };
        case actionTypes.FA_CLEAR_DASH_WIDGETS_CONFIG_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_DASH_WIDGETS_CONFIG: [],
            };
        case actionTypes.FA_CLEAR_ALL_DASH_WIDGETS_CONFIG_FORM_DATA:
            return {
                ...state,
                FA_SAVE_DASH_WIDGETS_CONFIG: [],
                FA_DASH_WIDGETS_CONFIG_LIST: []
            };
        /**
         * ADMIN REPORTS TEMPLATES
         */
        case actionTypes.FA_REPORT_TEMPLATES_LIST:
            return {
                ...state,
                FA_REPORT_TEMPLATES_LIST: action.reportTemplatesList,
            };
        case actionTypes.FA_REPORT_TEMPLATES_LIST_CLEAR:
            return {
                ...state,
                FA_REPORT_TEMPLATES_LIST: []
            };
        case actionTypes.FA_REPORT_TEMPLATES_INFO:
            return {
                ...state,
                FA_REPORT_TEMPLATES_INFO: action.reportTemplatesInfo,
            };
        case actionTypes.FA_SAVE_REPORT_TEMPLATES:
            return {
                ...state,
                FA_SAVE_REPORT_TEMPLATES: action.reportTemplatesSavedInfo,
            };
        case actionTypes.FA_CLEAR_REPORT_TEMPLATES_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_REPORT_TEMPLATES: [],
            };
        case actionTypes.FA_CLEAR_ALL_REPORT_TEMPLATES_FORM_DATA:
            return {
                ...state,
                FA_SAVE_REPORT_TEMPLATES: [],
                FA_REPORT_TEMPLATES_INFO: []
            };
        /**
         *  ADMIN -> TET
         * */
        case actionTypes.FA_TET_INFO:
            return {
                ...state,
                FA_TET_INFO: action.tetInfo,
            };
        case actionTypes.FA_SAVE_TET:
            return {
                ...state,
                FA_SAVE_TET: action.tetSavedInfo,
            };
        case actionTypes.FA_CLEAR_TET_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_TET: [],
            };
        case actionTypes.FA_CLEAR_ALL_TET_FORM_DATA:
            return {
                ...state,
                FA_SAVE_TET: [],
                FA_TET_INFO: []
            };
        case actionTypes.FA_ADICIONAL_INVOICE_INFO:
            return {
                ...state,
                FA_ADICIONAL_INVOICE_INFO: action.adicionalInvoiceInfo,
            };

            /**
         *  ADMIN -> VVP
         * */
        case actionTypes.FA_VVP_INFO:
            return {
                ...state,
                FA_VVP_INFO: action.vvpInfo,
            };
        case actionTypes.FA_SAVE_VVP:
            return {
                ...state,
                FA_SAVE_VVP: action.vvpSavedInfo,
            };
        case actionTypes.FA_CLEAR_VVP_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_VVP: [],
            };
        case actionTypes.FA_CLEAR_ALL_VVP_FORM_DATA:
            return {
                ...state,
                FA_SAVE_VVP: [],
                FA_VVP_INFO: []
            };

        /**
         *  ADMIN -> COMPANY
         * */
        case actionTypes.FA_COMPANY_INFO:
            return {
                ...state,
                FA_COMPANY_INFO: action.companyInfo,
            };
        case actionTypes.FA_SAVE_COMPANY:
            return {
                ...state,
                FA_SAVE_COMPANY: action.companySavedInfo,
            };
        case actionTypes.FA_CLEAR_COMPANY_SAVED_INFO:
            return {
                ...state,
                FA_SAVE_COMPANY: [],
            };
        case actionTypes.FA_CLEAR_ALL_COMPANY_FORM_DATA:
            return {
                ...state,
                FA_SAVE_COMPANY: [],
                FA_COMPANY_INFO: []
            };


        default:
            return state;
    }
};

export default reducer;