export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const RESET = 'RESET';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = 'BOX_LAYOUT';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const CHANGE_PRE_LAYOUT = 'CHANGE_PRE_LAYOUT';

/****** ALL FORMS CONTROL -> LOSE NOT SAVED INFO ***********/
export const FA_FORM_LOCK_CONTROL = 'FA_FORM_LOCK_CONTROL';
export const FA_CLEAR_FORM_LOCK_CONTROL = 'FA_CLEAR_FORM_LOCK_CONTROL';

/* FLEETAUTO GENERAL */
export const FA_AGENCIES = 'FA_AGENCIES';

export const FA_DRIVER_SEARCH = 'FA_DRIVER_SEARCH';//
export const FA_DRIVER_HISTORY_SEARCH = 'FA_DRIVER_HISTORY_SEARCH';//

export const FA_DRIVER_INFO = 'FA_DRIVER_INFO';//
export const FA_DRIVER_INFO_FOR_INVOICE = 'FA_DRIVER_INFO_FOR_INVOICE';//
export const FA_CLEAR_DRIVER_INFO_FOR_INVOICE = 'FA_CLEAR_DRIVER_INFO_FOR_INVOICE';

export const FA_DRIVERS_LIST = 'FA_DRIVERS_LIST';//
export const FA_DRIVERS_HISTORY_LIST = 'FA_DRIVERS_HISTORY_LIST';//

export const FA_CARS_LIST = 'FA_CARS_LIST';//

export const FA_BOOKING_TOLLS_LIST = 'FA_BOOKING_TOLLS_LIST';

//DASHBOARDS
export const FA_CLEAR_ALL_DASHBOARD_SERVICES = 'FA_CLEAR_ALL_DASHBOARD_SERVICES';
export const FA_DASHBOARD_BOOKINGS = 'FA_DASHBOARD_BOOKINGS';

//CALENDAR DATA
export const FA_OCCUPATION_CALENDAR_ITEMS = 'FA_OCCUPATION_CALENDAR_ITEMS';
export const FA_BOOKINGS_CALENDAR_ITEMS = 'FA_BOOKINGS_CALENDAR_ITEMS';
export const FA_BOOKINGS_GROUP_CALENDAR_ITEMS = 'FA_BOOKINGS_GROUP_CALENDAR_ITEMS';

//BOOKING CAR
export const FA_BOOKING_CAR_SAVE = 'FA_BOOKING_CAR_SAVE';
export const FA_CAR_CHANGE_MOTIVES = 'FA_CAR_CHANGE_MOTIVES';

export const FA_CLEAR_BOOKING_CAR_INFO = 'FA_CLEAR_BOOKING_CAR_INFO';

export const FA_SAVE_ITEM_BOOKING = 'FA_SAVE_ITEM_BOOKING';
export const FA_SAVE_EXTRA_BOOKING = 'FA_SAVE_EXTRA_BOOKING';
export const FA_SAVE_DRIVER_BOOKING = 'FA_SAVE_DRIVER_BOOKING';

export const FA_CLEAR_DRIVER_INFO = 'FA_CLEAR_DRIVER_INFO';
export const FA_CLEAR_DRIVER_INFO_2 = 'FA_CLEAR_DRIVER_INFO_2';

export const FA_OPERATOR_SELECT = 'FA_OPERATOR_SELECT';
export const FA_CAR_FUEL_RANGE = 'FA_CAR_FUEL_RANGE';
export const FA_COUNTRY_SELECT = 'FA_COUNTRY_SELECT';
export const FA_AGENCIES_RATE = 'FA_AGENCIES_RATE';
export const FA_STATIONS = 'FA_STATIONS';
export const FA_GROUPS = 'FA_GROUPS';

export const FA_EXTRAS_LIST = 'FA_EXTRAS_LIST';
export const FA_EXTRA_CALC = 'FA_EXTRA_CALC';

export const FA_CAR_PLATES = 'FA_CAR_PLATES';
export const FA_CAR_PLATES_CHANGE = 'FA_CAR_PLATES_CHANGE';

export const FA_AVAILABILITIES_LIST = 'FA_AVAILABILITIES_LIST';

export const FA_SAVE_DRIVER = 'FA_SAVE_DRIVER';
export const FA_SAVE_DRIVER_ACTION = 'FA_SAVE_DRIVER_ACTION';
//PAYMENTS
export const FA_PAYMENTS_LIST = 'FA_PAYMENTS_LIST';
export const FA_PAYMENTS_SAVE = 'FA_PAYMENTS_SAVE';

export const FA_MOVEMENTS_TYPES = 'FA_MOVEMENTS_TYPES';
export const FA_PAYMENTS_TYPES = 'FA_PAYMENTS_TYPES';

//BOOKING STATUSES
export const FA_BOOKING_STATUSES = 'FA_BOOKING_STATUSES';

/* FLEETAUTO SAVE*/
export const FA_SAVE_BOOKING = 'FA_SAVE_BOOKING';

/* CLEAR */
export const FA_CLEAR_ALL_BOOKING_DATA = 'FA_CLEAR_ALL_BOOKING_DATA';
export const FA_CLEAR_BOOKING_SAVED_INFO = 'FA_CLEAR_BOOKING_SAVED_INFO';

export const FA_CLEAR_EXTRA_INFO = 'FA_CLEAR_EXTRA_INFO';
export const FA_CLEAR_DRIVERS_LIST = 'FA_CLEAR_DRIVERS_LIST';
export const FA_CLEAR_DRIVER_SEARCH = 'FA_CLEAR_DRIVER_SEARCH';
export const FA_CLEAR_DRIVER_HISTORY_SEARCH = 'FA_CLEAR_DRIVER_HISTORY_SEARCH';
export const FA_CLEAR_DRIVER_ACTION = 'FA_CLEAR_DRIVER_ACTION';

export const FA_CLEAR_PAYMENT_SAVE = 'FA_CLEAR_PAYMENT_SAVE';

/* FLEETAUTO DOCUMENTS */
export const FA_BOOKINGS_LIST = 'FA_BOOKINGS_LIST';
export const FA_CLEAR_BOOKINGS_LIST = 'FA_CLEAR_BOOKINGS_LIST';
export const FA_BOOKING_INFO = 'FA_BOOKING_INFO';
export const FA_RENTALAGREEMENTS = 'FA_RENTALAGREEMENTS';

/* DOCUMENTS -> FLIGHT'S */
export const FA_FLIGHTS_CORRECTION_LIST = 'FA_FLIGHTS_CORRECTION_LIST';
export const FA_FLIGHTS_CORRECTION = 'FA_FLIGHTS_CORRECTION';
export const FA_FLIGHTS_CORRECTION_CLEAR = 'FA_FLIGHTS_CORRECTION_CLEAR';
export const FA_FLIGHTS_CORRECTION_LIST_CLEAR = 'FA_FLIGHTS_CORRECTION_LIST_CLEAR';

/* INVOICES -> BOOKINGS */
export const FA_BOOKING_TO_INVOICE_LIST = 'FA_BOOKING_TO_INVOICE_LIST';
export const FA_BOOKING_INVOICED_SAVED_INFO = 'FA_BOOKING_INVOICED_SAVED_INFO';
export const FA_CLEAR_BOOKING_INVOICED_SAVED_INFO = 'FA_CLEAR_BOOKING_INVOICED_SAVED_INFO';
export const FA_CLEAR_BOOKING_TO_INVOICE_LIST = 'FA_CLEAR_BOOKING_TO_INVOICE_LIST';

/* TABLES -> GROUPS */
export const FA_GROUP_LIST = 'FA_GROUP_LIST';
export const FA_GROUP_LIST_CLEAR = 'FA_GROUP_LIST_CLEAR';
export const FA_ITEMS_OF_GROUPS = 'FA_ITEMS_OF_GROUPS';
export const FA_GROUP_INFO = 'FA_GROUP_INFO';
export const FA_SAVE_GROUP = 'FA_SAVE_GROUP';
export const FA_CLEAR_GROUP_SAVED_INFO = 'FA_CLEAR_GROUP_SAVED_INFO';
export const FA_CLEAR_ALL_GROUP_FORM_DATA = 'FA_CLEAR_ALL_GROUP_FORM_DATA';

export const FA_GROUP_EXT_LINK_LIST = 'FA_GROUP_EXT_LINK_LIST';

/* TABLES -> BRAND */
export const FA_BRAND_LIST = 'FA_BRAND_LIST';
export const FA_BRAND_LIST_CLEAR = 'FA_BRAND_LIST_CLEAR';
export const FA_BRAND_INFO = 'FA_BRAND_INFO';
export const FA_SAVE_BRAND = 'FA_SAVE_BRAND';
export const FA_CLEAR_BRAND_SAVED_INFO = 'FA_CLEAR_BRAND_SAVED_INFO';
export const FA_CLEAR_ALL_BRAND_FORM_DATA = 'FA_CLEAR_ALL_BRAND_FORM_DATA';

/* TABLES -> CAR*/
export const FA_CAR_LIST = 'FA_CAR_LIST';
export const FA_CAR_INSURER_LIST = 'FA_CAR_INSURER_LIST';
export const FA_CAR_EXT_LINK_LIST = 'FA_CAR_EXT_LINK_LIST';
export const FA_CAR_LIST_CLEAR = 'FA_CAR_LIST_CLEAR';
export const FA_CAR_INFO = 'FA_CAR_INFO';
export const FA_SAVE_CAR = 'FA_SAVE_CAR';
export const FA_CLEAR_CAR_SAVED_INFO = 'FA_CLEAR_CAR_SAVED_INFO';
export const FA_CLEAR_ALL_CAR_FORM_DATA = 'FA_CLEAR_ALL_CAR_FORM_DATA';
export const FA_ITEM_STATUSES_LIST = 'FA_ITEM_STATUSES_LIST';
export const FA_FUEL_LIST = 'FA_FUEL_LIST';

/* TABLES -> CAR -> MODEL */
export const FA_CAR_MODEL_LIST = 'FA_CAR_MODEL_LIST';
export const FA_CAR_MODEL_LIST_CLEAR = 'FA_CAR_MODEL_LIST_CLEAR';
export const FA_CAR_MODEL_INFO = 'FA_CAR_MODEL_INFO';
export const FA_SAVE_CAR_MODEL = 'FA_SAVE_CAR_MODEL';
export const FA_CLEAR_CAR_MODEL_SAVED_INFO = 'FA_CLEAR_CAR_MODEL_SAVED_INFO';
export const FA_CLEAR_ALL_CAR_MODEL_FORM_DATA = 'FA_CLEAR_ALL_CAR_MODEL_FORM_DATA';

/* TABLES -> BIKE */
export const FA_BIKE_LIST = 'FA_BIKE_LIST';
export const FA_BIKE_LIST_CLEAR = 'FA_BIKE_LIST_CLEAR';
export const FA_BIKE_INFO = 'FA_BIKE_INFO';
export const FA_SAVE_BIKE = 'FA_SAVE_BIKE';
export const FA_CLEAR_BIKE_SAVED_INFO = 'FA_CLEAR_BIKE_SAVED_INFO';
export const FA_CLEAR_ALL_BIKE_FORM_DATA = 'FA_CLEAR_ALL_BIKE_FORM_DATA';

/* TABLES -> BIKE -> MODEL */
export const FA_BIKE_MODEL_LIST = 'FA_BIKE_MODEL_LIST';
export const FA_BIKE_MODEL_LIST_CLEAR = 'FA_BIKE_MODEL_LIST_CLEAR';
export const FA_BIKE_MODEL_INFO = 'FA_BIKE_MODEL_INFO';
export const FA_SAVE_BIKE_MODEL = 'FA_SAVE_BIKE_MODEL';
export const FA_CLEAR_BIKE_MODEL_SAVED_INFO = 'FA_CLEAR_BIKE_MODEL_SAVED_INFO';
export const FA_CLEAR_ALL_BIKE_MODEL_FORM_DATA = 'FA_CLEAR_ALL_BIKE_MODEL_FORM_DATA';

/* TABLES -> SPORTSWEAR */
export const FA_SPORTSWEAR_LIST = 'FA_SPORTSWEAR_LIST';
export const FA_SPORTSWEAR_LIST_CLEAR = 'FA_SPORTSWEAR_LIST_CLEAR';
export const FA_SPORTSWEAR_INFO = 'FA_SPORTSWEAR_INFO';
export const FA_SAVE_SPORTSWEAR = 'FA_SAVE_SPORTSWEAR';
export const FA_CLEAR_SPORTSWEAR_SAVED_INFO = 'FA_CLEAR_SPORTSWEAR_SAVED_INFO';
export const FA_CLEAR_ALL_SPORTSWEAR_FORM_DATA = 'FA_CLEAR_ALL_SPORTSWEAR_FORM_DATA';

/* TABLES -> TENT */
export const FA_TENT_LIST = 'FA_TENT_LIST';
export const FA_TENT_LIST_CLEAR = 'FA_TENT_LIST_CLEAR';
export const FA_TENT_INFO = 'FA_TENT_INFO';
export const FA_SAVE_TENT = 'FA_SAVE_TENT';
export const FA_CLEAR_TENT_SAVED_INFO = 'FA_CLEAR_TENT_SAVED_INFO';
export const FA_CLEAR_ALL_TENT_FORM_DATA = 'FA_CLEAR_ALL_TENT_FORM_DATA';

/* TABLES -> SERVICE */
export const FA_SERVICE_LIST = 'FA_SERVICE_LIST';
export const FA_SERVICE_LIST_CLEAR = 'FA_SERVICE_LIST_CLEAR';
export const FA_SERVICE_INFO = 'FA_SERVICE_INFO';
export const FA_SAVE_SERVICE = 'FA_SAVE_SERVICE';
export const FA_CLEAR_SERVICE_SAVED_INFO = 'FA_CLEAR_SERVICE_SAVED_INFO';
export const FA_CLEAR_ALL_SERVICE_FORM_DATA = 'FA_CLEAR_ALL_SERVICE_FORM_DATA';

/* TABLES -> AGENCY */
export const FA_AGENCY_LIST = 'FA_AGENCY_LIST';
export const FA_AGENCY_LIST_CLEAR = 'FA_AGENCY_LIST_CLEAR';
export const FA_AGENCY_INFO = 'FA_AGENCY_INFO';
export const FA_SAVE_AGENCY = 'FA_SAVE_AGENCY';
export const FA_CLEAR_AGENCY_SAVED_INFO = 'FA_CLEAR_AGENCY_SAVED_INFO';
export const FA_CLEAR_ALL_AGENCY_FORM_DATA = 'FA_CLEAR_ALL_AGENCY_FORM_DATA';

/* TABLES -> STATION */
export const FA_STATION_LIST = 'FA_STATION_LIST';
export const FA_STATION_LIST_CLEAR = 'FA_STATION_LIST_CLEAR';
export const FA_STATION_INFO = 'FA_STATION_INFO';
export const FA_SAVE_STATION = 'FA_SAVE_STATION';
export const FA_CLEAR_STATION_SAVED_INFO = 'FA_CLEAR_STATION_SAVED_INFO';
export const FA_CLEAR_ALL_STATION_FORM_DATA = 'FA_CLEAR_ALL_STATION_FORM_DATA';
export const FA_LOCATION_LIST = 'FA_LOCATION_LIST';
export const FA_LOCATION_LIST_CLEAR = 'FA_LOCATION_LIST_CLEAR';

export const FA_STATION_HOLIDAYS_LIST = 'FA_STATION_HOLIDAYS_LIST';

/* TABLES -> DEALER */
export const FA_DEALER_LIST = 'FA_DEALER_LIST';
export const FA_DEALER_LIST_CLEAR = 'FA_DEALER_LIST_CLEAR';
export const FA_DEALER_INFO = 'FA_DEALER_INFO';
export const FA_SAVE_DEALER = 'FA_SAVE_DEALER';
export const FA_CLEAR_DEALER_SAVED_INFO = 'FA_CLEAR_DEALER_SAVED_INFO';
export const FA_CLEAR_ALL_DEALER_FORM_DATA = 'FA_CLEAR_ALL_DEALER_FORM_DATA';

/* TABLES -> INSURER */
export const FA_INSURER_LIST = 'FA_INSURER_LIST';
export const FA_INSURER_LIST_CLEAR = 'FA_INSURER_LIST_CLEAR';
export const FA_INSURER_INFO = 'FA_INSURER_INFO';
export const FA_SAVE_INSURER = 'FA_SAVE_INSURER';
export const FA_CLEAR_INSURER_SAVED_INFO = 'FA_CLEAR_INSURER_SAVED_INFO';
export const FA_CLEAR_ALL_INSURER_FORM_DATA = 'FA_CLEAR_ALL_INSURER_FORM_DATA';

/* TABLES -> DRIVER */
export const FA_DRIVER_LIST = 'FA_DRIVER_LIST';
export const FA_DRIVER_LIST_CLEAR = 'FA_DRIVER_LIST_CLEAR';
export const FA_DRIVER_INFO_TABLE = 'FA_DRIVER_INFO_TABLE';//already exist FA_DRIVER_INFO
export const FA_CLEAR_DRIVER_SAVED_INFO = 'FA_CLEAR_DRIVER_SAVED_INFO';
export const FA_CLEAR_ALL_DRIVER_FORM_DATA = 'FA_CLEAR_ALL_DRIVER_FORM_DATA';

/* TABLES -> FEES */
export const FA_FEE_LIST = 'FA_FEE_LIST';
export const FA_FEE_LIST_CLEAR = 'FA_FEE_LIST_CLEAR';
export const FA_FEE_INFO = 'FA_FEE_INFO';
export const FA_SAVE_FEE = 'FA_SAVE_FEE';
export const FA_CLEAR_FEE_SAVED_INFO = 'FA_CLEAR_FEE_SAVED_INFO';
export const FA_CLEAR_ALL_FEE_FORM_DATA = 'FA_CLEAR_ALL_FEE_FORM_DATA';

/* TABLES -> RATE */
export const FA_RATE_LIST = 'FA_RATE_LIST';
export const FA_RATE_LIST_CLEAR = 'FA_RATE_LIST_CLEAR';
export const FA_RATE_GROUP_LIST = 'FA_RATE_GROUP_LIST';
export const FA_AGREEMENT_LIST = 'FA_AGREEMENT_LIST';
export const FA_RATE_GROUP_LIST_CLEAR = 'FA_RATE_GROUP_LIST_CLEAR';
export const FA_RATE_INFO = 'FA_RATE_INFO';
export const FA_SAVE_RATE = 'FA_SAVE_RATE';
export const FA_RATE_GROUP_INFO = 'FA_RATE_GROUP_INFO';
export const FA_SAVE_RATE_GROUP = 'FA_SAVE_RATE_GROUP';
export const FA_CLEAR_RATE_GROUP_INFO = 'FA_CLEAR_RATE_GROUP_INFO';
export const FA_CLEAR_RATE_GROUP_SAVED_INFO = 'FA_CLEAR_RATE_GROUP_SAVED_INFO';
export const FA_CLEAR_RATE_SAVED_INFO = 'FA_CLEAR_RATE_SAVED_INFO';
export const FA_CLEAR_ALL_RATE_FORM_DATA = 'FA_CLEAR_ALL_RATE_FORM_DATA';

export const FA_VALID_EQUATION = 'FA_VALID_EQUATION';//VALIDATE EQUATIONS 
export const FA_CLEAR_VALID_EQUATION = 'FA_CLEAR_VALID_EQUATION';

/* TABLES -> USER */
export const FA_USER_LIST = 'FA_USER_LIST';
export const FA_USER_LIST_CLEAR = 'FA_USER_LIST_CLEAR';
export const FA_USER_INFO = 'FA_USER_INFO';
export const FA_SAVE_USER = 'FA_SAVE_USER';
export const FA_CLEAR_USER_SAVED_INFO = 'FA_CLEAR_USER_SAVED_INFO';
export const FA_CLEAR_ALL_USER_FORM_DATA = 'FA_CLEAR_ALL_USER_FORM_DATA';

/* ADMIN -> CONFIG */
export const FA_CONFIG_LIST = 'FA_CONFIG_LIST';
export const FA_CONFIG_LIST_CLEAR = 'FA_CONFIG_LIST_CLEAR';
export const FA_SAVE_CONFIG = 'FA_SAVE_CONFIG';
export const FA_CLEAR_CONFIG_SAVED_INFO = 'FA_CLEAR_CONFIG_SAVED_INFO';
export const FA_CLEAR_ALL_CONFIG_FORM_DATA = 'FA_CLEAR_ALL_CONFIG_FORM_DATA';

/* ADMIN -> CONFIG */
export const FA_DASH_WIDGETS_CONFIG_LIST = 'FA_DASH_WIDGETS_CONFIG_LIST';
export const FA_SAVE_DASH_WIDGETS_CONFIG = 'FA_SAVE_DASH_WIDGETS_CONFIG';
export const FA_CLEAR_DASH_WIDGETS_CONFIG_SAVED_INFO = 'FA_CLEAR_DASH_WIDGETS_CONFIG_SAVED_INFO';
export const FA_CLEAR_ALL_DASH_WIDGETS_CONFIG_FORM_DATA = 'FA_CLEAR_ALL_DASH_WIDGETS_CONFIG_FORM_DATA';
export const FA_DASH_WIDGETS_CONFIG_LIST_CLEAR = 'FA_DASH_WIDGETS_CONFIG_LIST_CLEAR';

/* ADMIN -> PERS -> REPORT TEMPLATES */

export const FA_REPORT_TEMPLATES_LIST = 'FA_REPORT_TEMPLATES_LIST';
export const FA_REPORT_TEMPLATES_LIST_CLEAR = 'FA_REPORT_TEMPLATES_LIST_CLEAR';
export const FA_CLEAR_REPORT_TEMPLATES_SAVED_INFO = 'FA_CLEAR_REPORT_TEMPLATES_SAVED_INFO';
export const FA_CLEAR_ALL_REPORT_TEMPLATES_FORM_DATA = 'FA_CLEAR_ALL_REPORT_TEMPLATES_FORM_DATA';
export const FA_SAVE_REPORT_TEMPLATES = 'FA_SAVE_REPORT_TEMPLATES';
export const FA_REPORT_TEMPLATES_INFO = 'FA_REPORT_TEMPLATES_INFO';

/* ADMIN -> T&T INVOICE */
export const FA_TET_INFO = 'FA_TET_INFO';
export const FA_SAVE_TET = 'FA_SAVE_TET';
export const FA_CLEAR_TET_SAVED_INFO = 'FA_CLEAR_TET_SAVED_INFO';
export const FA_CLEAR_ALL_TET_FORM_DATA = 'FA_CLEAR_ALL_TET_FORM_DATA';
export const FA_ADICIONAL_INVOICE_INFO = 'FA_ADICIONAL_INVOICE_INFO';

/* ADMIN -> VVP */
export const FA_VVP_INFO = 'FA_VVP_INFO';
export const FA_SAVE_VVP = 'FA_SAVE_VVP';
export const FA_CLEAR_VVP_SAVED_INFO = 'FA_CLEAR_VVP_SAVED_INFO';
export const FA_CLEAR_ALL_VVP_FORM_DATA = 'FA_CLEAR_ALL_VVP_FORM_DATA';

/* ADMIN -> COMPANY */
export const FA_COMPANY_INFO = 'FA_COMPANY_INFO';
export const FA_SAVE_COMPANY = 'FA_SAVE_COMPANY';
export const FA_CLEAR_COMPANY_SAVED_INFO = 'FA_CLEAR_COMPANY_SAVED_INFO';
export const FA_CLEAR_ALL_COMPANY_FORM_DATA = 'FA_CLEAR_ALL_COMPANY_FORM_DATA';